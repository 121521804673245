import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MessagingIntegrationsService } from '@core/services/third-party-integrations/messaging-integrations.service';
import { APP_SLUGS, MAIN_SLUGS } from '@routes/routes.types';

export const inbGoToConnectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const integrationsS = inject(MessagingIntegrationsService);
    const redirectUrl = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.inbox}`;

    const effRef = effect(() => {
        if (integrationsS.isAnyChannelConnected()) {
            router.navigate([redirectUrl]);
            effRef.destroy();
        }
    });
    return true;
};
